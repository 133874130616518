<template>
  <CreatorHome v-if="$isCreator" />
  <CreatorBecome v-else />
</template>
<script>
export default {
  components: {
    CreatorHome: () => import("./CreatorHome.vue"),
    CreatorBecome: () => import("../verify-creator/CreatorBecome.vue"),
  },
};
</script>
